<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{ path: `${breadCrumbItem.path}` }"
        v-for="(breadCrumbItem,index) in breadCrumbList"
        :key="index"
        @click="$router.go(-1)"
        >{{ breadCrumbItem.title }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    breadCrumbList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>
